<template>
  <mdb-btn outline="info" darkWaves rounded size="sm">{{ field }}</mdb-btn>
</template>

<script>
import {mdbBtn} from "mdbvue";
export default {
  name: "FieldTag",
  components: {
    mdbBtn,
  },
  props: {
    field: String
  }
}
</script>

<style scoped>

</style>