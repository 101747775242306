<template>
  <tr>
    <td>{{ count }}</td>
    <td>{{ partners.id }}</td>
    <td><img :src="partners.logo" style="max-width: 150px" alt="logo" v-if="partners.logo"></td>
    <td>{{ partners.partner_name }}</td>
    <td><a :href="partners.homepage" target="_blank">{{ partners.homepage }}</a></td>
    <td>
      <TdTagField :field="partners.fields[i] " v-for="(a,i) in partners.fields" :key="i"/>
    </td>
    <td>
      <router-link :to="{name : 'admin-partner-update', query: {id: partners.id}}">
        <button type="button" class="btn btn-indigo btn-sm m-0  m-1">수정</button>
      </router-link>
      <button type="button" class="btn btn-danger btn-sm m-0 m-1" @click="onDeleteData(partners.id)">삭제</button>
    </td>
  </tr>
</template>
<script>

import TdTagField from "../../../../components/FieldTag";
import firebase from "firebase/app";

export default {
  name: "TrTag",
  components: {
    TdTagField,
  },
  props: {
    partners: Object,
    count: Number
  },
  methods: {
    onDeleteData(id) {
      const db = firebase.firestore();
      db.collection("Partners")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.$emit('update');
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },
    onUpdateData() {

    },
  }
}
</script>

<style scoped>

</style>